import {routeDict} from '~/router'

let menu = [
    {
        icon: 'fas fa-video', text: 'Course', regex: /^\/(course|stream-archive|reviews)/,
        items: [
            // {icon: 'fas fa-layer-group', text: 'Series List', link: '/adminv/course/series'},
            {icon: 'fas fa-th-list', text: 'Enrollment List', route: routeDict['enrollment-list']},
            {icon: 'fas fa-tags', text: 'Course Tag', route: routeDict['course-tags']}
        ]
    },
    {
        icon: 'fas fa-chalkboard-teacher', text: 'Tutor', regex: /^\/(instructors|invoice-list)/,
        items: [
            {icon: 'fas fa-users', text: 'Tutor List', route: routeDict['instructors-list']},
            {icon: 'fas fa-file-invoice', text: 'Invoice List', route: routeDict['invoice-list']}
        ]
    },
    {
        icon: 'fas fa-chart-bar', text: 'Reports', regex: /^\/(business-dashboard|tutor-dashboard|report)/,
        items: [
            {icon: 'fas fa-chart-area', text: 'Business Performance', route: routeDict['business-dashboard']},
            {icon: 'fas fa-chart-bar', text: 'Tutor Performance', route: routeDict['tutor-dashboard']},
            {icon: 'fas fa-video', text: 'Course Performance', route: routeDict['report-course']},
            {icon: 'fas fa-layer-group', text: 'Series Performance', route: routeDict['report-series']},
            {icon: 'fas fa-layer-group', text: 'Courses Series Report', route: routeDict['series-report']}
        ]
    }
]

export default menu
