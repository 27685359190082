import {Instructor} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'

export default class InstructorsData {
    static instructors: Instructor[] = []
    static instructorDict = new Map<(number | string), Instructor>()

    static async init() {
        if(!this.instructors.length)
            await this.update()
    }

    static async update() {
        const res = await createRequest('/instructors', 'get').send()
        this.instructors = res.data.instructors
        this.instructorDict = new Map<(number | string), Instructor>()
        for(const i of this.instructors) {
            this.instructorDict.set(i.member_id, i)
            this.instructorDict.set(i._id, i)
        }
    }

    static getInstructor(midOrId: number | string): Instructor | undefined {
        return this.instructorDict.get(midOrId)
    }

    static async shouldGetInstructor(midOrId: number | string): Promise<Instructor | undefined> {
        let found = this.getInstructor(midOrId)
        if(!found) {
            await this.update()
            found = this.getInstructor(midOrId)
        }
        return found
    }
}
