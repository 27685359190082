import {Course} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'

export default class CourseData {
    static courseDict: { [key: string]: Course } = {}

    static async updateCourse(courseId: string, course?: Course): Promise<Course> {
        if (course) {
            this.courseDict[courseId] = course
        } else {
            const res = await createRequest(`/course/${courseId}/admin`, 'get').send()
            this.courseDict[courseId] = res.data as Course
        }
        return this.courseDict[courseId]
    }

    static getCourse(courseId: string): Course {
        return this.courseDict[courseId]
    }

    static async shouldGetCourse(courseId: string): Promise<Course> {
        let course = this.getCourse(courseId)
        if (!course)
            course = await this.updateCourse(courseId)
        return course
    }
}
