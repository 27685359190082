import {createRequest, Cache} from '~/utils/network-request'

export interface DistrictOption {
    id: number
    name: string
}

export class District {
    id: number = -1
    name: string = ''
    districts: DistrictLocation[] = []
}

export class Location {
    id: string = ''
    address: string = ''
    weekday: string = ''
    weekend: string = ''
}

export class DistrictLocation {
    name: string = ''
    locations: Location[] = []
}

const cacheKey = 'sf-cache'

export const sfStations = []
export const sfLockers = []
export const sfLookup: { [key: string]: Location } = {}
let cache: Cache = {etag: '', value: null}

function setValue(data) {
    function replaceArrayContent(oldA: any[], newA: any[]) {
        oldA.length = 0
        oldA.push(...newA)
    }

    replaceArrayContent(sfStations, data.stations)
    replaceArrayContent(sfLockers, data.lockers)

    function setToDict(dict, districts: District[]) {
        for (const shippingPoint of districts) {
            for (const district of shippingPoint.districts) {
                for (const location of district.locations) {
                    dict[location.id] = location
                }
            }
        }
    }

    // no need to clear old lookup obj
    setToDict(sfLookup, sfStations)
    setToDict(sfLookup, sfLockers)
    setToDict(sfLookup, data.removed_stations)
    setToDict(sfLookup, data.removed_lockers)
}

function setCache(c) {
    cache = c
    try {
        localStorage.setItem(cacheKey, LZString.compress(JSON.stringify(cache)))
    } catch {
        //QuotaExceededError
    }
}

export async function updateLocations() {
    if (!cache.etag) {
        const storageStr = localStorage.getItem(cacheKey)
        if (storageStr)
            cache = JSON.parse(LZString.decompress(storageStr)) || {etag: '', value: null}
    }

    const res = await createRequest(`/sf-locations`, 'get').setCache(cache).send()
    setCache(res.cache)

    if (res.status === 304 && !sfStations.length)
        setValue(res.cache!!.value)
    if (res.status === 200)
        setValue(res.data)
}
