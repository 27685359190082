





































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {Instructor, Member} from '~/components/data-class/data-class'
import InstructorsData from '~/components/instructor/instructors-data'
import {userPropicURL} from '~/utils/misc'

type MidOrId = string | number
@Component({
    components: {}
})
export default class InstructorPopover extends Vue {
    @Prop({
        default: () => {
            return ''
        }
    })
    value!: MidOrId

    @Prop({
        default: () => {
            return ''
        }
    })
    memberId: string

    @Prop({
        default: () => {
            return 'small'
        }
    })
    size: string

    instructor: Instructor = new Instructor()

    get propicURL() {
        return userPropicURL(this.instructor.member_id, 180)
    }
    async load() {
        this.instructor = await InstructorsData.shouldGetInstructor(this.value) || new Instructor()
    }

    @Watch('value')
    async idChanged() {
        await this.load()
    }
}
