






import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({
    components: {}
})
export default class OrderLink extends Vue {
    @Prop({
        default: ''
    })
    orderId: string
}
