




















import {Component, Prop, Vue, Emit} from 'vue-property-decorator'
import {courseSubjects} from "@afterschool.dev/as-data-admin";

@Component({
    components: {}
})
export default class SubjectSelect extends Vue {
    @Prop({default: ''})
    value!: string
    subjectOptions: Array<{ value: string, label: string }> = []

    @Prop()
    size: string

    @Prop({default: 'Subject'})
    placeholder

    @Prop({default: false})
    clearable: boolean

    @Prop({default: false})
    disabled: boolean

    instructors: object[] = []
    instructorLoading: boolean = false

    created() {
        courseSubjects.forEach((subject) => {
            this.subjectOptions.push({
                value: subject,
                label: subject
            })
        })
    }

    @Emit()
    input(value: string) {
        //
    }

    get localValue(): string {
        return this.value
    }

    set localValue(value: string) {
        this.input(value)
    }
}
