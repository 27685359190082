import {CourseSeries} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'
import _ from 'lodash'

export default class SeriesData {
    static series: CourseSeries[] = []
    static seriesDict: { [key: string]: CourseSeries } = {}

    static async init() {
        if (!_.keys(this.seriesDict).length)
            await this.update()
    }

    static async update() {
        const res = await createRequest('/courses/series', 'get', {limit: 0}).send()
        this.series = res.data.series
        this.seriesDict = _.keyBy(res.data.series, '_id')
    }

    static getSeries(seriesId: number): CourseSeries | undefined {
        return this.seriesDict[seriesId]
    }

    static async shouldGetSeries(seriesId: number): Promise<CourseSeries | undefined> {
        let found = this.getSeries(seriesId)
        if (!found) {
            await this.update()
            found = this.getSeries(seriesId)
        }
        return found
    }
}
