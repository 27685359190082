

























import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import CourseData from '../course/course-data'
import {Course} from '~/components/data-class/data-class'

@Component({
    components: {}
})
export default class CourseSelect extends Vue {
    @Prop({default: ''})
    value!: string

    @Prop()
    size: string

    @Prop({default: 'Course'})
    placeholder

    @Prop({default: () => new Course()})
    course: Course

    @Prop(Boolean)
    offline: boolean

    @Prop()
    series: number

    @Prop()
    instructor: string | number

    @Prop()
    not: string

    searchedCourses: Course[] = []
    searchCourseLoading: boolean = false

    async created() {
        if (this.value) {
            await this.searchCourses(this.value)
        }
    }

    @Watch('value')
    async searchCourses(queryString: string) {
        this.searchedCourses = []
        if (queryString !== '') {
            this.searchCourseLoading = true
            const q: any = {
                keyword: queryString,
                status: 'published,pending,preordering,private,hidden',
                limit: 0
            }
            if (this.offline)
                q.offline = 1
            if (this.series)
                q.series = this.series
            if (this.instructor)
                q.instructor = this.instructor
            if (this.not)
                q.not = this.not

            const res = await createRequest('courses/admin', 'get', q).send()

            this.searchedCourses = res.data.courses
            this.searchCourseLoading = false

            for (const course of this.searchedCourses) {
                await CourseData.updateCourse(course._id, course)
                if (course._id === this.value)
                    this.$emit('update:course', course)
            }
        }
    }

    @Emit()
    input(value: string) {
        //
    }

    get localValue(): string {
        return this.value
    }

    set localValue(value: string) {
        this.input(value)
    }
}
