
























import {Component, Prop, Vue, Emit, Watch} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'

@Component({
    components: {}
})
export default class MemberSelect extends Vue {
    @Prop({default: ''})
    value!: string | string[]

    @Prop()
    size: string

    @Prop({default: 'Member'})
    placeholder

    get multiple() {
        return (this.value instanceof Array)
    }

    searchedMembers: object[] = []
    searchMemberLoading: boolean = false

    created() {
        if (this.value) {
            this.searchMembers(this.value)
        }
    }

    @Watch('value')
    searchMembers(queryString: string | string[]) {
        if (queryString !== '' && !(queryString instanceof Array)) {
            this.searchedMembers = []
            this.searchMemberLoading = true
            createRequest('members', 'get', {
                keyword: queryString
            }).send()
                .then((res) => {
                    this.searchedMembers = res.data.members
                    this.searchMemberLoading = false
                })
        }
        else if (queryString === '') {
            this.searchedMembers = []
        }
        else if ((queryString instanceof Array) && this.searchedMembers.length === 0) {
            createRequest('members/by-identifiers', 'post', {}, {
                identifierValues: this.value
            }).send()
                .then((res) => {
                    this.searchedMembers = res.data.members
                })
        }
    }

    @Emit()
    input(value: string | string[]) {
        //
    }

    get localValue(): string | string[] {
        return this.value
    }

    set localValue(value: string | string[]) {
        this.input(value)
    }
}
