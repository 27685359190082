import {Member} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'

export default class MemberData {
    static memberDict: { [key: string]: Member } = {}

    static async updateMember(memberId: string, member?: Member): Promise<Member> {
        if (member) {
            this.memberDict[memberId] = member
        } else {
            let member
            try {
                const res =  await createRequest(memberId + '/user-profile/self', 'get', {number: 'true', school_number: 'true'}).send(true, false)
                member = res.data
            } catch (e)  {
                member = new Member()
                member.display_name = 'User not found'
            }
            this.memberDict[memberId] = member as Member
        }
        return this.memberDict[memberId]
    }

    static getMember(memberId: string): Member {
        return this.memberDict[memberId]
    }

    static async shouldGetMember(memberId: string): Promise<Member> {
        let member = this.getMember(memberId)
        if (!member)
            member = await this.updateMember(memberId)
        return member
    }
}
