

















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {Member} from '~/components/data-class/data-class'
import MemberData from '~/components/member/member-data'
import {userPropicURL} from '~/utils/misc'

@Component({
    components: {}
})
export default class MemberPopover extends Vue {
    @Prop({
        default: () => {
            return ''
        }
    })
    memberId: string

    @Prop({
        default: () => {
            return 'small'
        }
    })
    size: string

    member: Member = Member.from({display_name: 'Loading...', member_wallet: {coins: 0, diamonds: 0}})
    loadedMemberId: string = ''
    loaded = false

    get propicURL() {
        return userPropicURL(this.memberId, 180)
    }
    async load() {
        if (this.loadedMemberId === this.memberId)
            return

        this.member = await MemberData.shouldGetMember(this.memberId)
        this.loadedMemberId = this.member._id
        this.loaded = true
    }

    @Watch('memberId')
    resetMember() {
        this.member = Member.from({display_name: 'Loading...', member_wallet: {coins: 0, diamonds: 0}})
    }
}
