




















import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'
import {CourseSeries} from '~/components/data-class/data-class'
import SeriesData from '~/components/series/series-data'

@Component({
    components: {}
})
export default class ElSeriesSelect extends Vue {
    @Prop({default: ''})
    value!: number

    @Prop()
    size: string

    @Prop({default: 'Series'})
    placeholder

    @Prop({default: true})
    withId

    @Prop({default: () => new CourseSeries()})
    series: CourseSeries

    //  DATA
    seriesList: CourseSeries[] = []

    async created() {
        // GET series
        await SeriesData.update()
        this.seriesList = SeriesData.series
    }

    formatOption(series) {
        return `[${series._id}] ${series.title}`
    }

    @Emit()
    input(value: number) {
        //
    }

    @Watch('value')
    vChanged(value: number) {
        this.$emit('update:series', this.seriesList.find(i => i._id === value))
    }

    get localValue(): number {
        return this.value
    }

    set localValue(value: number) {
        this.input(value)
    }
}
