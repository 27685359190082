




















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {Course} from '~/components/data-class/data-class'
import CourseData from '~/components/course/course-data'

@Component({
    components: {}
})
export default class CoursePopover extends Vue {
    @Prop({
        default: () => {
            return ''
        }
    })
    courseId: string

    @Prop({
        default: () => {
            return 'small'
        }
    })
    size: string

    course: Course = new Course()
    loadedCourseId: string = ''

    get thumbnailURL() {
        return (this.course.thumbnailSquareURL || this.course.thumbnailURL) + '-h100'
    }
    async load() {
        if (this.loadedCourseId === this.courseId)
            return

        this.course = await CourseData.shouldGetCourse(this.courseId)
        this.loadedCourseId = this.course._id
    }

    @Watch('courseId')
    resetCourse() {
        this.course = new Course()
    }
}
