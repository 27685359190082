<template>
    <paginate
        :clickHandler="updatePage"
        :containerClass="cssClass.container"
        :next-class="cssClass.nextPrev"
        :next-link-class="cssClass.nextPrevLink"
        :page-class="cssClass.page"
        :page-link-class="cssClass.pageLink"
        :pageCount="totalPage"
        :prev-class="cssClass.nextPrev"
        :prev-link-class="cssClass.nextPrevLink"
        next-text="<i class='as-arrow-right small'></i>"
        prev-text="<i class='as-arrow-left small'></i>"
        v-model="page">
    </paginate>
</template>

<script>
    import Vue from 'vue'
    import paginate from './vuejs-paginate'

    Vue.component('paginate', paginate)

    export default {
        data() {
            return {
                page: this.current,
                circle: {
                    container: 'pagination pagination-round pagination-primary justify-content-center',
                    nextPrev: 'page-item mx-1',
                    nextPrevLink: 'page-link iconbox iconbox-custom rounded-circle',
                    page: 'page-item mx-1',
                    pageLink: 'page-link iconbox iconbox-custom',
                },
                square: {
                    container: 'pagination pagination-square pagination-primary justify-content-end',
                    nextPrev: 'page-item mx-1',
                    nextPrevLink: 'page-link',
                    page: 'page-item mx-1',
                    pageLink: 'page-link',
                },
            }
        },
        props: {
            totalPage: {
                type: Number,
                required: true
            },
            current: {
                type: Number,
                required: true
            },
            cssStyle: {
                type: String,
                default: 'circle'
            }
        },
        computed: {
            cssClass: function () {
                return this.cssStyle === 'circle' ? this.circle : this.square
            }
        },
        watch: {
            current: function (newCurrent, oldCurrent) {
                this.page = newCurrent
            }
        },
        created: function () {
            this.page = this.current
        },
        methods: {
            updatePage(page) {
                this.$emit('callUpdatePage', page)
            },

        }
    }
</script>

<style lang="scss">
    @import 'public/static/scss/nuxt';

    .pagination .page-link:hover {
        background: #ddfbef !important;
        color: map-get($theme-colors, primary) !important;
    }

    .pagination-square {
        .page-link {
            min-width: px-to-rem(32)!important;
            min-height: px-to-rem(32)!important;
            padding: px-to-rem(5)!important;
            font-size: px-to-rem(14) !important;
        }
    }
</style>
