



















import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {sfLookup} from '~/components/sf-express/sf-location-storage'

@Component({
    components: {}
})
export default class SFSelect extends Vue {
    @Prop({default: ''})
    value!: string
    subjectOptions: Array<{ value: string, label: string }> = []

    @Prop()
    size: string

    @Prop({default: 'SFExpress Code'})
    placeholder

    @Prop({default: false})
    clearable: boolean

    sfExpressOptions: { value: string, title: string }[] = []

    created() {}

    mounted() {
        this.sfExpressOptions = Object.keys(sfLookup).map((code) => {
            const title = `${code} | ${sfLookup[code].address}`
            return {value: code, title: title.substr(0, 40)}
        })
    }

    @Emit()
    input(value: string) {
        //
    }

    get localValue(): string {
        return this.value
    }

    set localValue(value: string) {
        this.input(value)
    }
}
