

























import {Component, Vue} from 'vue-property-decorator'
import PaginationMixin from '../../mixins/pagination-mixin'

@Component({
    components: {}
})
export default class PaginationRow extends Vue {
    value: PaginationMixin = new PaginationMixin()

    created() {
        this.value = this.$parent as PaginationMixin
    }
}
