





















import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import InstructorsData from '~/components/instructor/instructors-data'
import {Course, Instructor} from '~/components/data-class/data-class'

@Component({
    components: {}
})
export default class InstructorSelect extends Vue {
    @Prop({default: ''})
    value!: string

    @Prop()
    size: string

    @Prop({default: 'Instructor'})
    placeholder

    @Prop({default: true})
    withId

    @Prop({default: () => new Instructor()})
    instructor: Instructor

    @Prop({default: false})
    disabled: boolean

    instructors: Instructor[] = []

    async created() {
        // GET tutors
        await InstructorsData.update()
        this.instructors = InstructorsData.instructors
    }

    @Emit()
    input(value: string) {
        //
    }

    @Watch('value')
    vChanged(value: string) {
        this.$emit('update:instructor', this.instructors.find(i => i.member_id === value))
    }

    get localValue(): string {
        return this.value
    }

    set localValue(value: string) {
        this.input(value)
    }
}
