import navigation from '~/configs/navigation'
import icons from '~/configs/icons'

const configs = {
    product: {
        name: 'AfterSchool Admin',
        version: '99.99.99'
    },

    navigation,

    icons
}

export default configs
