
























import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import BlogData from '../blog/blog-data'
import {BlogPost} from '~/components/data-class/data-class'

@Component({
    components: {}
})
export default class BlogSelect extends Vue {
    @Prop({default: '' || []})
    value!: string[] | string

    @Prop()
    size: string

    get multipleSelect() {
      return (this.value instanceof Array)
    }

    @Prop({default: 'Blog'})
    placeholder!: string

    searchedBlogs: BlogPost[] = []
    searchBlogLoading: boolean = false
    async created() {
        if (this.value)
           await this.searchBlogs(this.value)
    }

    @Watch('value')
    async searchBlogs(queryString: string | string[]) {
        this.searchedBlogs = []
        if (queryString !== '' && !(queryString instanceof Array)) {
            this.searchBlogLoading = true
            const res = await createRequest('/blog/blog-posts', 'get', {
              status: '-1,0,1,2',
              limit: -1,
              keyword: queryString
            }).send()

            this.searchedBlogs = res.data.blog_posts
            this.searchBlogLoading = false

        }
        else if ((queryString instanceof Array) && this.searchedBlogs.length === 0) {
            this.searchBlogLoading = true
            const res = await createRequest('/blog/blog-posts', 'get', {
              status: '-1,0,1,2',
              limit: -1,
              blog_ids: queryString.join(',')
            }).send()
                .then((res) => {
                    this.searchedBlogs = res.data.blog_posts
                    this.searchBlogLoading = false
                })
        }

        for (const blog of this.searchedBlogs) {
            await BlogData.updateBlog(blog._id, blog)
        }
    }

    @Emit()
    input(value: string | string[]) {
        //
    }

    get localValue(): string | string[] {
        return this.value
    }

    set localValue(value: string | string[]) {
        this.input(value)
    }
}
