import {OfflineClass} from '~/components/course/offline-course-model'
import {isEmpty, isEmptyString} from '~/utils/misc'
import Serializable from '~/utils/serializable'
import {AccessLimitType} from "@afterschool.dev/as-data-admin";
import {PaymentMethod} from '@afterschool.dev/as-data-admin'

export {PaymentMethod} from '@afterschool.dev/as-data-admin'

export interface DataObject {
    [key: string]: any
}

// Course List and Add Course
export interface SectionItem {
    _id: string,
    title: string,
    video_id: string,
    preview: boolean,
    type: number,
    size: number,
}

export interface Section {
    title: string,
    items: SectionItem[],
}

export interface CourseFile {
    title: string
    url: string
    preview: boolean
}

export enum CourseStatus {
    PENDING = 0,
    PUBLISHED = 1,
    HIDDEN = 2,
    PREORDERING = 3,
    PRIVATE = 4
}

export let courseStatusOptions: Array<{ value: number, label: string }> = [
    {
        value: CourseStatus.PENDING,
        label: 'Pending'
    },
    {
        value: CourseStatus.PUBLISHED,
        label: 'Published'
    },
    {
        value: CourseStatus.HIDDEN,
        label: 'Hidden'
    },
    {
        value: CourseStatus.PREORDERING,
        label: 'Pre-ordering'
    },
    {
        value: CourseStatus.PRIVATE,
        label: 'Private'
    }
]

export class CourseEditable {
    title: string = ''
    owner: string = ''

    meta_description: string = ''
    level: string = ''
    form: number[] = []
    discounted_price: number = 0
    original_price: number = 0

    access_limit_type: AccessLimitType = AccessLimitType.INFINITE_USE
    refund_days: number = 14

    overview: string = ''

    subject: string = ''
    tags: string[] = []
    // available: boolean = false
    add_enrolled_count: number = 0
    add_ratings: object = {}
    status: number = 0
    language: number = -1
    pages: number = 0

    thumbnailURL: string = ''
    thumbnailSquareURL: string = ''
    catalog_image: string = ''
    url_title: string = ''

    // curriculum: Section[] = [] //

    handling_fee: number = 0
    split_value: number = -1

    hardworking_weeks: number = 0

    discount_from: number = 0
    discount_until: number = 0
    discount_title: string = ''
    note_type: NoteType = NoteType.NONE
    note_ready_time: number = 0
    offline: boolean = false
    offline_data: CourseOfflineData = new CourseOfflineData()

    // trial_message_data: TrialMessageData = new TrialMessageData()
    trial_referral_data: TrialReferralData = new TrialReferralData()

    webhook: string = ''
}

export class Course extends CourseEditable {
    _id: string = '' //
    curriculum: Section[] = []
    price: number = 0 //
    files: CourseFile[] = [] //
    enrolled_count: number = 0 //
    current_version_enrolled: number = 0
    series_id: number = -1 // readonly
    display_name: string = ''
    current_version: number = 1
    created: number = 0
    thumbnail: ResizableImage = new ResizableImage()
    thumbnail_square: ResizableImage = new ResizableImage()

    trial_message_data: TrialMessageData = new TrialMessageData()
}

export class CourseVersionEditable {

    title: string = ''

    overview: string = ''

    lesson_count: number = 0
    length: number = 0
    note_type: NoteType = NoteType.PHYSICAL
    language: NoteLanguage = NoteLanguage.NONE // NoteLanguage
    pages: number = 0 // NotePages

    // curriculum: Section[] = []
    // files: CourseFile[] = []

    refund_days: number = 14
    access_limit_type: AccessLimitType = AccessLimitType.INFINITE_USE
    offline: boolean = false
    offline_data: CourseOfflineData = new CourseOfflineData()
}


export class CourseVersion extends CourseVersionEditable {

    thumbnailURL: string = ''
    thumbnailSquareURL: string = ''
    thumbnail: ResizableImage = new ResizableImage()
    thumbnail_square: ResizableImage = new ResizableImage()
}

export class CourseS extends Serializable {
    _id: string = ''

    title: string = ''
    url_title: string = ''
    price: number = 0
    discount_from: number = 0
    discount_until: number = 0
    discount_title: string = ''
    discounted_price: number = -1
    original_price: number = -1
    handling_fee: number = -1

    level: string = ''
    subject: string = ''
    tags: string[] = []
    overview: string = ''
    meta_description: string = ''

    lesson_count: number = 0
    length: number = 0
    note_type: NoteType = NoteType.NONE
    language: number = 0 // NoteLanguage
    pages: number = 0 // NotePages

    curriculum: Section[] = []
    files: CourseFile[] = []
    owner: string = ''
    instructor_id: number = -1

    thumbnailURL: string = ''
    thumbnailSquareURL: string = ''
    catalog_image: string = ''
    enrolled_count: number = 0
    rating: number = 0
    rating_count: number = 0
    ratings: object = {}
    add_ratings: object = {}

    add_enrolled_count: number = 0
    status: number = 0
    refund_days: number = -1
    access_limit_type: AccessLimitType = AccessLimitType.INFINITE_USE

    hardworking_weeks: number = 0

    created: number = 0
    modified: number = 0

    vimeo_project: string = ''
    edit_status: CourseEditStatus = CourseEditStatus.PASSED

    offline: boolean = false
    offline_data: CourseOfflineData = new CourseOfflineData()

    // not saved
    display_name?: string
    url_name?: string
    // hardworking?: HardWorkingData
    edit_review?: CourseReview
}

export class CourseOfflineData extends Serializable {

    first_class_start: number
    first_class_end: number
    last_class_start: number
    last_class_end: number
    quota_percent: number
    paid: number

    trial: boolean = false
    registration_start: number = 0
    registration_start_days_before: number = -1

    // time_slots: TimeSlot[] = [];
    next_course: string = ''

    classes_order: number[] = []
    classes: OfflineClass[] = [] // denormalize, no direct edit

}

export class TrialMessageData {
    series_title: string = ''
    replay_link: string = ''
    coupon_code: string = ''
    discount: number = 0
    landing_page_link: string = ''
    templates: any[] = []
}

export class TrialReferralData {
    _id: string
    note: string = ''
    note_value: number = 0

    series: number = -1
    course_coupon_value: number = 0

    referer_message: string = ''
    referee_message: string = ''
}

export class TimeSlot extends Serializable {
    id: number // table index
    title: string = ''
    hidden: boolean = true
    weekday: number = 0 // 0-6
    start_time: number = 0
    end_time: number = 0

    dates: number[] = []
    location: string = ''
    language: NoteLanguage = NoteLanguage.CHINESE
    stream: boolean = false
    video: boolean = false

    whatsapp_link: string = ''
    zoom_link: string = ''
    zoom_number: string = ''
    zoom_password: string = ''

    quota: number = 0
    applied: number // readonly
    paid: number // readonly
    preserved: number // readonly
    min_applied: number = 0
    quota_percent: number
    quota_left: number
    applied_percent: number // readonly
    noti: boolean // readonly

    constructor(index) {
        super()
        this.id = index
    }
}

export class EnrollmentOfflineData {
    name: string = ''
    email: string = ''
    phone: string = ''
    time_slot: number = 0
}

export class Enrollment {
    _id: string = ''
    member_id: string = ''
    course: string = ''
    status: EnrollmentStatus = EnrollmentStatus.UNPAID
    enrolled_time: number = 0

    order_id: string = ''

    created: number = 0
    paid_time: number = 0

    hw_reward_redeemed: number = 0

    renewed: boolean | undefined
    offline_data?: EnrollmentOfflineData
}

export class OfflineEnrollment extends Enrollment {
    offline_data: EnrollmentOfflineData
}

export enum EnrollmentStatus {
    IN_CART = -1,
    UNPAID = 0,
    PAID = 1,
    CANCELED = 2
}

export enum NoteType {
    NONE = 0,
    PHYSICAL = 1,
    PHYSICAL_NOT_READY = 2,
    ONLINE = 3,
}

export let noteTypeOptions: Array<{ value: number, label: string }> = [
    {
        value: NoteType.NONE,
        label: '無筆記'
    },
    {
        value: NoteType.PHYSICAL,
        label: '有筆記'
    },
    {
        value: NoteType.ONLINE,
        label: '電子筆記'
    },
]

export enum NoteLanguage {
    NONE = -1,
    CHINESE = 0,
    ENGLISH = 1,
    BOTH = 2,
    EITHER = 3
}

export let languageOptions: Array<{ value: number, label: string }> = [
    {
        value: NoteLanguage.NONE,
        label: '不設筆記'
    }, {
        value: NoteLanguage.CHINESE,
        label: '只有中文'
    }, {
        value: NoteLanguage.ENGLISH,
        label: '只有英文'
    }, {
        value: NoteLanguage.BOTH,
        label: '中英對照'
    }, {
        value: NoteLanguage.EITHER,
        label: '供中、英選擇'
    },
]

export let noteLanguages = {
    [NoteLanguage.NONE]: '不設',
    [NoteLanguage.CHINESE]: '中文',
    [NoteLanguage.ENGLISH]: '英文',
    [NoteLanguage.BOTH]: '中英對照'
}

export enum WeekDay {
    SUN,
    MON,
    TUE,
    WED,
    THU,
    FRI,
    SAT
}

export let weekDayOptions: Array<{ value: number, label: string }> = [
    {
        value: WeekDay.SUN,
        label: '星期日'
    }, {
        value: WeekDay.MON,
        label: '星期一'
    }, {
        value: WeekDay.TUE,
        label: '星期二'
    }, {
        value: WeekDay.WED,
        label: '星期三'
    }, {
        value: WeekDay.THU,
        label: '星期四'
    }, {
        value: WeekDay.FRI,
        label: '星期五'
    }, {
        value: WeekDay.SAT,
        label: '星期六'
    }
]

export let minAppliedOptions: Array<{ min: number, max: number, label: string, color: string }> = [
    {
        min: 0,
        max: 50,
        label: '尚有充足名額',
        color: '#4DBBB6'
    }, {
        min: 50,
        max: 75,
        label: '尚餘少量位置',
        color: '#FFC232'
    }, {
        min: 75,
        max: 100,
        label: '即將爆滿',
        color: '#FF3D71'
    }, {
        min: 100,
        max: 100,
        label: '已經爆滿',
        color: '#9f9bb3'
    }
]

export enum OrderStatus {
    VERIFICATION_FAILED = -4,
    TO_BE_VERIFIED = -3,
    REFUNDED = -2,
    CANCELED = -1,
    PENDING = 0,
    PAID = 1,
    MAILED = 2
}

export enum MailingStatus {
    NO_NEED = -1,
    PENDING = 0,
    TB_MAILED = 1,
    MAILED = 2
}

export class ReSend {
    course_id: string = ''
    language: NoteLanguage = NoteLanguage.NONE
}

// Order List and Details
export class Order {
    _id: string = ''
    member_id: string = ''
    courses: OrderCourse[] = []
    display_name: string = ''
    original_price: number = 0
    price: number = 0
    discount: number = 0
    diamond_used: number = 0
    referral_code: string = ''
    payment_method: PaymentMethod = PaymentMethod.NOT_SELECTED
    shipping_code: string = ''
    phone: string = ''
    waybill_number: string = ''
    status: OrderStatus = OrderStatus.PENDING // OrderStatus
    mailing_status: MailingStatus = MailingStatus.NO_NEED
    created: number = 0
    paid_time: number = 0

    verification_image: string = ''
    failed_reason: string = ''

    resend: ReSend[] = []

    // readonly
    last_sent: string
    days: number
    user_type: string
    xban: XBan

    // coupon
    coupon: {
        user_coupon_id: string,
        coupon_id: number,
        coupon_type: RewardType,
        coupon_data: CourseDiscountCouponData
    }
    coupon_title: string

    offline_data: { [key: string]: EnrollmentOfflineData } = {}
}

export class OrderCourse {
    course_id: string = ''
    language: number = -1
    original_price: number = 0
    price: number = 0
    refunded: boolean = false
    title: string = ''
    refund_days: number = 0
    note: boolean = false
    coupon_deduct: number = 0

    offline_data: CourseOfflineData = new CourseOfflineData()

    progress: number = 0
}

// add order with course
export class AddOrderCourse {
    course_id: string = ''
    price: number = 0
    note: boolean = false
}

export class AddOrder {
    member_id: string = ''
    courses: AddOrderCourse[] = []
    price: number = 0
    shipping_code: string = ''
    phone: string = ''
    waybill_number: string = ''
    status: OrderStatus = OrderStatus.PENDING
    offline_data: any = {}
    languages: {} = {} // {MATH0001: 1}
}

// instructor
export class Instructor extends Serializable {
    _id: number = 0
    member_id: string = ''
    tagline: string = ''
    description: string = ''
    subjects: string[] = []
    display_name: string = ''
    url_name: string = ''
    status: string = ''
    bank: string = ''
    bank_account: string = ''
    bank_account_holder: string = ''
    split_value: number = 0.5
    referral_split_value: number = 0.85
    phone: string = ''
    own_instructor: boolean = false
    show: boolean = true
    video_id: string = ''
    phone_no: string = ''
    sleekflow_number: string = ''
    sleekflow_key: string = ''

    student_sheet: string = ''
    sales_sheet: string = ''

    referrer_reward: number = 0
    referrer_min_spend: number = 0
    referee_reward: number = 0
    referee_min_spend: number = 0

    rtmp: string = ''
    stream_key: string = ''

    assistants: string[] = []
}

// Course Sales
export class CourseSalesModel {
    total_sales: number = 0
    total_enroll: number = 0
    month_sales: number = 0
    month_enroll: number = 0
    details: CourseSalesOrder[]
}

export class CourseSalesOrder {
    order_id: string = ''
    paid_time: string = ''
    display_name: string = ''
    progress: number = 0
    review: Review
    refunded: boolean = false
    amount: number = 0
    member_id: string = ''
}

const reviewHideReasons = [
    'Short',
    'Profanity',
    'Repeat',
    'Copy self',
    'Copy others',
    'Blacklist',
    'Manual'
] as const
export type ReviewHideReasons = typeof reviewHideReasons[number];

export class Review {
    _id: string = ''
    member_id: string = ''
    course_id: string = ''
    rating: number = 0
    word_redeemed: boolean = false

    comment: string = ''
    l: number = 0
    modified: number = Date.now()
    hide: boolean = false
    reason?: ReviewHideReasons

    display_name?: string
    course_title?: string
    course_url_title?: string
    course_status?: CourseStatus
}

// Instructor Sales
export class InstructorSalesModel {
    course_count: { [key: string]: string } = {}
    month_course_count: [] = []
    count: number = 0
    month_count: number = 0
    total: number = 0
    month_total: number = 0
    details: InstructorSalesOrder[] = []
}

export class InstructorSalesOrder {
    order_id: string = ''
    course_id: string = ''
    price: number = 0
    gain: number = 0
    referral: string = ''
    created: number = 0
    paid_time: number = 0
    status: number = 0
}

export class CourseDashboard {
    sales: number = 0
    count: number = 0
    form: FormValue[] = []
    sales_list: { [key: string]: { [key: string]: { [key: string]: number } } } = {}
    order_list: { [key: string]: { [key: string]: { [key: string]: number } } } = {}
}

export interface FormValue {
    count: number
    sales: number
    courses: number
}

//  Cart Report
export class CartReport {
    _id: string = ''
    display_name: string = ''
    diamonds: number = 0
    phone: string = ''
    form: string = ''
    courses: string[] = []
    created_oldest: number = 0
    created_latest: number = 0
    last_added: number = 0 // now - created_latest, in days

    user_type: string = ''
    last_sent: string = ''
}

//  Student Learning Report
export class LearningReport {
    _id: string = ''
    member_id: string = ''
    display_name: string = ''
    phone: string = ''
    course_id: string = ''
    title: string = ''
    progress: number = 0
    rating: number = 0
    comment: string = ''
    enrolled_time: number = 0
    last_accessed: number = 0
    completed: number = 0
    hw_reward: number = 0
    hw_reward_period: number = 0
    hw_reward_redeemed: number = 0
}

export class FreeLearnerReportRow {
    _id: string = ''
    enrolled: number = 0
    _enrolled: string = ''
    enrolled_for: number = 0
    _enrolled_for: string = ''
    course_id: string = ''
    course_title: string = ''
    member_id: string = ''
    last_learnt: number = 0
    _last_learnt: string = ''
    percentage: number = 0
    _percentage: string = ''
    display_name: string = ''
    phone: string = ''
    rating: number = 0
    _rating: string = ''
    comment: string = ''
    last_sent: string = ''
    instructor_id: number = 0
    instructor_display_name: string = ''

    purchased: boolean = false
    diamonds: number = 0

    user_type: string = ''
}

export class PaidLearnerReportRow extends FreeLearnerReportRow {
    hw_reward_amount: number = 0
    hw_remain_days: number = 0
    _hw_remain_days: string = ''
    hw_redeemed: boolean = false
    hw_expired: boolean = false
}

export class CourseSeries {
    _id: number = 0
    courses: string[] = []
    subject: string = ''
    title: string = ''
    offline: boolean = false
    landing: boolean = false
    show_landing: boolean = false
    description: string = ''
    meta_description: string = ''
    modified: number = 0
    status: CourseSeriesStatus = CourseSeriesStatus.SHOW

    instructor: string = ''
    instructor_id: number = 0

    thumbnail_url: string = ''

    instructor_display_name?: string = ''
    data?: CourseSeriesData[] = []
    // enrollment?: Dict<Enrollment>
    // progress?: Dict<Progress>
}

export class CourseSeriesData {
    _id: string
    title: string
    thumbnailURL: string
    price: number
}

export class SeriesLandingData {
    _id: number = 0

    package: {
        icon: string,
        content: string
    }[] = []

    overview: string = '' //課程概覧 html
    features: {
        image: string,
        title: string,
        subtitle: string,
        content: string
    }[] = [] //課程特色

    set: string = '' //課程配套 html
    set_image: string = ''
    courses: SeriesCourse[] = [] //課程總覧
    qna: { q: string, a: string }[] = []

    // out
    series: CourseSeries = new CourseSeries()
    instructor?: Instructor = new Instructor()
    reviews?: Review[] = []
    review_count: number = 0

    // rating: Rating = new Rating()

    url_title: string = ''
}

export class SeriesCourse {
    topic: string
    course_id: string
    contents: string[]

    // out
    url_title?: string = ''
    registration_start?: number = 0
    last_class_end?: number = 0
    enrolled_count?: number = 0
    discounted_price?: number = 0
    original_price?: number = 0
    discount_title?: string = ''
    discount_from?: number = 0
    discount_until?: number = 0
}

export enum CourseSeriesStatus {
    HIDE = -1,
    SHOW = 0
}

export enum CourseEditStatus {
    DRAFT = -1,
    PASSED = 0,
    SUBMITTED = 1,
    REJECTED = 2
}

export class CourseReviewIssue {
    content: string = ''
    resolved: boolean = false
    image_url: string = ''
}

export class CourseReview {
    _id: string = ''
    updated: number = 0
    reviewed: number = 0
    status: CourseEditStatus = CourseEditStatus.DRAFT
    issues: CourseReviewIssue[] = []
}

export interface CourseReviewData {
    combined: CourseS
    original: CourseS
    edited: InstructorCourseEditable
}

export class InstructorCourseEditable {

    title?: string = ''
    url_title?: string = ''
    price?: number = 0
    discounted_price?: number = 0
    original_price?: number = 0
    handling_fee?: number = 0

    level?: string = ''
    subject?: string = ''
    tags?: string[] = []
    overview?: string = ''
    meta_description?: string = ''

    lesson_count?: number = 0
    length?: number = 0
    language?: number = 0
    pages?: number = 0

    owner?: string = ''
    instructor_id?: number = -1

    thumbnailURL?: string = ''
    thumbnailSquareURL?: string = ''

    status?: number = 0
    refund_days?: number = 0
    access_limit_type?: number = 0

    hardworking_weeks?: number = 0

    modified?: number = 0
    curriculum?: Section[] = []

    remove_videos?: object = {} // {[video_id]?: boolean}
    remove_files?: object = {} // {[url]?: boolean}
    files?: CourseFile[] = []
}

export class Range {
    min?: number
    max?: number

    static equals(a: Range, b: Range): boolean {
        if ((!a || !b) && a !== b)
            return false
        return a === b || (a.min === b.min && a.max === b.max)
    }
}

export class UserCriteria {
    progress?: Range
    lastLearnt?: Range
    enrolledFor?: Range
    hardwokingRemainDays?: Range
    rated?: boolean
    purchased?: boolean

    static equals(a: UserCriteria, b: UserCriteria) {
        const ranges = ['progress', 'lastLearnt', 'enrolledFor', 'hardworkingRemainDays']
        for (const key of ranges) {
            if (!Range.equals(a[key], b[key]))
                return false
        }
        return (a.rated === b.rated && a.purchased === b.purchased)
    }

    static fromLocal(local: LocalUserCriteria): UserCriteria {
        const u = new UserCriteria()
        u.progress = LocalUserCriteria.queryGetRange(local, 'progress')
        u.lastLearnt = LocalUserCriteria.queryGetRange(local, 'lastLearnt')
        u.enrolledFor = LocalUserCriteria.queryGetRange(local, 'enrolledFor')
        u.hardwokingRemainDays = LocalUserCriteria.queryGetRange(local, 'hwRemain')
        if (!isEmptyString(local.rated)) {
            u.rated = local.rated === 'true'
        }
        if (!isEmptyString(local.purchased)) {
            u.purchased = local.purchased === 'true'
        }
        return u
    }
}

export class LocalUserCriteria {
    progressMin: string = ''
    progressMax: string = ''
    lastLearntMin: string = ''
    lastLearntMax: string = ''
    enrolledForMin: string = ''
    enrolledForMax: string = ''
    hwRemainMin: string = ''
    hwRemainMax: string = ''
    rated: string = ''
    purchased: string = ''

    static queryGetRange(query: LocalUserCriteria, key: string): Range | undefined {
        const minKey = key + 'Min'
        const maxKey = key + 'Max'
        if (isEmptyString(query[minKey]) && isEmptyString(query[maxKey]))
            return undefined
        const range: Range = {}
        if (!isEmptyString(query[minKey]))
            range.min = Number(query[minKey])
        if (!isEmptyString(query[maxKey]))
            range.max = Number(query[maxKey])
        return range
    }

    static rangeToQuery(l: LocalUserCriteria, range: Range | undefined, key: string) {
        if (!range)
            return
        if (!isEmpty(range.min))
            l[key + 'Min'] = range.min
        if (!isEmpty(range.max))
            l[key + 'Max'] = range.max
    }

    static fromCriteria(c: UserCriteria) {
        const l = new LocalUserCriteria()
        LocalUserCriteria.rangeToQuery(l, c.progress, 'progress')
        LocalUserCriteria.rangeToQuery(l, c.lastLearnt, 'lastLearnt')
        LocalUserCriteria.rangeToQuery(l, c.enrolledFor, 'enrolledFor')
        LocalUserCriteria.rangeToQuery(l, c.hardwokingRemainDays, 'hwRemain')
        if (!isEmpty(c.rated))
            l.rated = c.rated!.toString()
        if (!isEmpty(c.purchased))
            l.purchased = c.purchased!.toString()
        return l
    }
}

export class CartUserCriteria {
    lastAdded?: Range

    static equals(a: CartUserCriteria, b: CartUserCriteria) {
        const ranges = ['lastAdded']
        for (const key of ranges) {
            if (!Range.equals(a[key], b[key]))
                return false
        }
        return true
    }

    static fromLocal(local: LocalCartUserCriteria): CartUserCriteria {
        const u = new CartUserCriteria()
        u.lastAdded = LocalCartUserCriteria.queryGetRange(local, 'lastAdded')
        return u
    }
}

export class LocalCartUserCriteria {
    lastAddedMin: string = ''
    lastAddedMax: string = ''

    static queryGetRange(query: LocalCartUserCriteria, key: string): Range | undefined {
        const minKey = key + 'Min'
        const maxKey = key + 'Max'
        if (isEmptyString(query[minKey]) && isEmptyString(query[maxKey]))
            return undefined
        const range: Range = {}
        if (!isEmptyString(query[minKey]))
            range.min = Number(query[minKey])
        if (!isEmptyString(query[maxKey]))
            range.max = Number(query[maxKey])
        return range
    }

    static rangeToQuery(l: LocalCartUserCriteria, range: Range | undefined, key: string) {
        if (!range)
            return
        if (!isEmpty(range.min))
            l[key + 'Min'] = range.min
        if (!isEmpty(range.max))
            l[key + 'Max'] = range.max
    }

    static fromCriteria(c: CartUserCriteria) {
        const l = new LocalCartUserCriteria()
        LocalCartUserCriteria.rangeToQuery(l, c.lastAdded, 'lastAdded')
        return l
    }
}

export class OrderUserCriteria {
    days?: Range
    status: string = ''

    static equals(a: OrderUserCriteria, b: OrderUserCriteria) {
        const ranges = ['days']
        for (const key of ranges) {
            if (!Range.equals(a[key], b[key]))
                return false
        }
        return a.status === b.status
    }

    static fromLocal(local: LocalOrderUserCriteria): OrderUserCriteria {
        const u = new OrderUserCriteria()
        u.days = LocalOrderUserCriteria.queryGetRange(local, 'days')
        u.status = local.status
        return u
    }
}

export class LocalOrderUserCriteria {
    daysMin: string = ''
    daysMax: string = ''
    status: string = ''

    static queryGetRange(query: LocalOrderUserCriteria, key: string): Range | undefined {
        const minKey = key + 'Min'
        const maxKey = key + 'Max'
        if (isEmptyString(query[minKey]) && isEmptyString(query[maxKey]))
            return undefined
        const range: Range = {}
        if (!isEmptyString(query[minKey]))
            range.min = Number(query[minKey])
        if (!isEmptyString(query[maxKey]))
            range.max = Number(query[maxKey])
        return range
    }

    static rangeToQuery(l: LocalOrderUserCriteria, range: Range | undefined, key: string) {
        if (!range)
            return
        if (!isEmpty(range.min))
            l[key + 'Min'] = range.min
        if (!isEmpty(range.max))
            l[key + 'Max'] = range.max
    }

    static fromCriteria(c: OrderUserCriteria) {
        const l = new LocalOrderUserCriteria()
        LocalOrderUserCriteria.rangeToQuery(l, c.days, 'days')
        l.status = c.status
        return l
    }
}

export enum MakeUpLessonStatus {
    EXPIRED = -2,
    CANCELED = -1,
    AVAILABLE = 0,
}

export class MakeUpLessonRecord extends Serializable {
    _id: number = 0

    members: string[] = []
    course_id: string = ''
    lessons: string[] = []
    instructor: string = ''
    instructor_id: number = 0
    operator: string = ''
    expire: number = 0
    created: number = 0

    status: MakeUpLessonStatus = MakeUpLessonStatus.AVAILABLE

    //out
    display_names?: { [key: string]: string }
    lesson_data?: { [key: string]: { title: string, size: number } }
    title?: string
    curriculum?: Section[]
    operator_display_name?: string

    static from(raw: object): MakeUpLessonRecord {
        const r: MakeUpLessonRecord = super.from(raw)
        if (r.expire < new Date().getTime() && r.status !== MakeUpLessonStatus.CANCELED)
            r.status = MakeUpLessonStatus.EXPIRED
        return r
    }
}

export interface MessageTemplate {
    _id: string,
    template: string,
    query: any,
    order: number
}

export class Wallet extends Serializable {
    coins: number = 500
    diamonds: number = 10
    experience: number = 0
    next_exp: number = 100
    level: number = 1
    sp: number = 0
    lv: number = 1
}

export enum Role {
    ADMIN = 'ADMIN',
    SADMIN = 'SADMIN',
    TUTOR = 'TUTOR',
    GRADUATE = 'GRADUATE',
    GRADUATE_UNVERIFIED = 'GRADUATE_UNVERIFIED',
    GRADUATE_REJECTED = 'GRADUATE_REJECTED',
    NONE = ''
}

export class Member extends Serializable {
    _id: string = ''
    email: string = ''
    phone: string = ''
    order_email: string = ''
    order_phone: string = ''
    name: string = ''
    display_name: string = ''
    school: number = 0
    school_verification: VerificationStatus = VerificationStatus.VERIFIED
    role: Role = Role.NONE
    form: string = ''
    elective: number[] = []
    status: string = ''
    description: string = ''
    title: number = -1
    // dse_result: DSEResult = null
    dse: number
    hide_school: boolean = false

    profile_picture_url = ''
    // profile_picture: ResizableImage = new ResizableImage()

    member_created_date: string = ''

    next_school_changeable = ''

    stars: number = 0
    message_token: boolean = false

    referrer: string = ''

    // profile only
    google_id: string = ''
    facebook_id: string = ''
    apple_id: string = ''

    // readonly
    banInfo: BanInfo | null = null
    xban: XBan | null = null
    member_wallet: Wallet = new Wallet()
    streak: object = {}
    auth: Auth = new Auth()
}

export class Auth {
    google_id: string = ''
    facebook_id: string = ''
    apple_id: string = ''
}

export class BlogPost extends Serializable {
    _id: string = ''

    tags: string[] = []
    title: string = ''
    meta_title: string = ''
    url_title: string = ''
    content: string = ''
    content_json: {} = {}
    meta_description: string = ''

    member_id: string = ''

    created_date: string = ''

    created: number = 0
    edited: number = 0
    released: number = 0
    clap_count: number = 0
    comment_count: number = 0
    last_comment: number = 0
    user_clapped?: string[] = []
    user_bookmarked?: string[] = []

    thumbnailURL: string = ''
    thumbnailSquareURL: string = ''
    thumbnailURL_large: string = ''
    thumbnailSquareURL_large: string = ''

    status: BlogPostStatus = 0
    publish_on: number = 0

    new_editor: boolean = false
}


export class BlogPostEditable {
    tags: string[] = []
    title: string = ''
    meta_title: string = ''
    url_title: string = ''
    content: string = ''
    content_json: {} = {}
    meta_description: string = ''

    member_id: string = ''

    edited: number = 0

    thumbnailURL: string = ''
    thumbnailSquareURL: string = ''
    thumbnailURL_large: string = ''
    thumbnailSquareURL_large: string = ''

    status: BlogPostStatus = 0
    publish_on: number = 0
}

export class BlogPostListOutput extends Serializable {
    _id: string = ''

    tags: string[] = []
    title: string = ''
    url_title: string = ''
    // content: string = ''
    meta_description: string = ''

    member_id: string = ''
    display_name: string = ''
    role: string = ''
    instructor_id?: number
    url_name?: string

    created_date: string = ''

    created: number = 0
    edited: number = 0
    released: number = 0
    clap_count: number = 0
    // comment_count: number = 0
    // last_comment: number = 0
    clapped: boolean = false
    bookmarked: boolean = false

    thumbnailURL: string = ''
    thumbnailSquareURL: string = ''
    thumbnailURL_large: string = ''
    thumbnailSquareURL_large: string = ''

    status: BlogPostStatus = 0
    publish_on: number = 0
}

export class BlogPostOutput extends BlogPostListOutput {
    content: string = ''

    related_courses?: Course[]
    related_blogs?: BlogPostListOutput[]
}

export enum BlogPostStatus {
    PENDING = 0,
    PUBLISHED = 1,
    HIDDEN = 2
}

export class UploadImage {
    localURL: string = ''
    file: any
}

export class NewBlogPost {
    tags: string[] = []
    title: string = ''
    content: string = ''
    content_json: {} = {}
    images: UploadImage[] = []

    member_id: string = ''
    display_name: string = ''

    released: number = 0

    thumbnail: ResizableImage = new ResizableImage()
    thumbnail_square: ResizableImage = new ResizableImage()

    new_thumbnail: any = ''
    new_thumbnail_square: any = ''

    thumbnailURL: string = ''
    thumbnailSquareURL: string = ''

    thumbnailURL_large: string = ''
    thumbnailSquareURL_large: string = ''

    status: number = 0

    meta_description: string = ''
    meta_title: string = ''
    url_title: string = ''

    publish_on: number = new Date().getTime()
    update: boolean = false

    new_editor: boolean = false
}

export class BanInfo {
    executor: string = ''
    reason: string = ''
    days: number = 0
    end_time: number = 0
}

export class XBan {
    _id: string = '' // member id
    days: number = 0 // gte 10000 -> forever
    until: number = 0 // timestamp
    reason: string = ''
    by: string = ''
}

export enum RewardType {
    COIN = 0,
    DIAMOND = 1,
    COURSE_DISCOUNT_FLAT = 2,
    COURSE_DISCOUNT_RATIO = 3,
    COURSE = 4
}

export class Reward {
    type: RewardType
    data: CourseDiscountCouponData | CurrencyRewardData | CourseRewardData

    constructor(type: RewardType, data: CourseDiscountCouponData | CurrencyRewardData | CourseRewardData) {
        this.type = type
        this.data = data
    }
}

export class Coupon extends Serializable {
    _id: number = -1
    // type: RewardType = RewardType.COIN
    // value: number | string = 0
    expire: number = 0
    title: string = ''
    code: string = ''
    phone_numbers: string[] = []
    created_by: string = ''
    created: number = 0

    system: boolean = false
    deleted: boolean = false

    reward: Reward = new Reward(RewardType.COIN, {amount: 0})
}

export enum DiscountCouponType {
    AfterSchool = -1,
    InstructorReferral = 1,
    Instructor = 2,
    Course = 3,
    InstructorStudentReferral = 4,
    Trial = 5,
    Series = 6
}

export interface CouponTier {
    min_spend: number
    amount: number
}

export interface CourseDiscountCouponData {
    min_spend: number
    amount: number
    tiers?: CouponTier[]

    type: DiscountCouponType
    expire: number
    scope: string
    split_type: SplitType
    split_value: number
    instructor?: string
    course_id?: string
    series_id?: number
    reusable: boolean
}

export interface CurrencyRewardData {
    amount: number
}

export interface CourseRewardData {
    course_id: string
    note: boolean
}

export class UserCoupon extends Serializable {
    _id: string = ''
    member_id: string = ''
    coupon_id: number = 0
    type: RewardType = RewardType.COURSE_DISCOUNT_FLAT
    value: number = 0
    expire: number = 0
    created: number = 0
    used: boolean = false

    data: CourseDiscountCouponData
}


export class ReceiptRecord {
    _id: string = ''
    receipt_id: string = ''
    member_id: string = ''
    value: number = 0
    success: boolean = false
    created: number = 0
    reason: string = ''
    data: object
}

export class ResizableImage {
    url: string = ''
    version: number = 0
    storage_path: string = ''
}

export enum ShopItemStatus {
    HIDDEN = 0,
    IN_STOCK = 1,
    OUT_OF_STOCK = 2
}

export enum ShopItemType {
    REAL = 0,
    VIRTUAL = 1
}

export class ShopItem extends Serializable {
    _id: number = -1
    type: ShopItemType = ShopItemType.REAL
    price: number = 0
    title: string = ''
    short: string = ''
    description: string = ''
    status: ShopItemStatus = ShopItemStatus.HIDDEN
    images: ResizableImage[] = []
    image_urls: string[] = []
    image_counter: number = 0

    created: number = 0
    expire: number = 0
}

export enum MailingType {
    SF = 0,
    WithCourse = 1
}

export class PurchaseRecord {
    _id: {
        member_id: string,
        id: number
    }

    shop_item_id: number = 0
    count: number = 1
    price: number = 0

    mailing_type: MailingType = MailingType.SF

    phone?: string
    shipping_code?: string
    order_id?: string

    waybill_number: string = ''

    created: number = 0

    // out
    title?: string
    image?: string
    member_phone?: string
    short?: string
    xban?: XBan

    constructor(memberId: string, itemId: number, type: MailingType) {
        this._id.member_id = memberId
        this.shop_item_id = itemId
        this.mailing_type = type
    }
}

export enum SplitType {
    AfterSchool = 0,
    Instructor = 1,
    Both = 2
}

export class WebBanner {
    title: string = ''
    subtitle: string = ''
    button: string = ''
    link: string = ''
    image_url: string = ''
    size: string = 'cover'
    position: string = 'center'
    bkg_color: string = '#43d0c0'

    start: number = 0
    end: number = 0
}

export class AppBanner {
    image_url: string = ''
    type: number = 1
    content: string = ''

    start: number = 0
    end: number = 0
}

export class OneTimeCodes {
    _id: number = 0
    codes: string[] = []
    redeemed: { [key: string]: string } = {}
    count: number = 1

    constructor(couponId: number) {
        this._id = couponId
    }

}

export enum VerificationType {
    SCHOOL = 0,
    DSE = 1
}

export enum VerificationStatus {
    REJECTED = -1,
    NOT_VERIFIED = 0,
    VERIFIED = 1
}

export interface DSEScore {
    [key: string]: number
}

export class DSEResult {
    verified: boolean = false
    status: VerificationStatus = VerificationStatus.NOT_VERIFIED // 1 verified, 0 pending, -1 rejected
    result: DSEScore = {}
}

export interface SchoolName {
    school: number
    name: string
}

export type FailedReason = 'name' | 'school' | 'card'

export class Verification extends Serializable {
    _id: string = ''
    type: VerificationType = VerificationType.SCHOOL
    member_id: string = ''
    data: DSEScore | SchoolName = {}
    created: number = 0
    status: VerificationStatus = VerificationStatus.NOT_VERIFIED
    member: Member = new Member()
    file_url: string = ''

    failed_card_type: string
    failed_reason: FailedReason[]
    rejected_count: number

    static from(raw: object): Verification {
        const data = raw['data']
        delete raw['data']
        const v: Verification = super.from(raw)
        v.data = data
        return v
    }
}

export class ShortenLink extends Serializable {
    _id: number = 0
    code: string = ''
    url: string = ''
    remark: string = ''
    created: number = 0
    access: number = 0
}

export class ShortenLinkEditable extends Serializable {
    code: string = ''
    url: string = ''
    remark: string = ''
}

export class WhatsAppGroupMetadata {
    isGroup: true = true
    participants: string[] = []
    groupInviteLink: string = ''
}

export class WhatsAppGroupMember {
    member_id: string = ''
    phone: string = ''

    tobe_added?: boolean
    tobe_removed?: boolean
}

export class WhatsAppGroup {
    _id: string = ''
    name: string = ''
    metadata: WhatsAppGroupMetadata = new WhatsAppGroupMetadata()
    last_time: number = 0

    classes: GroupClasses[] = []
    members: WhatsAppGroupMember[] = []
    tobe_removed: string[] = []
    tobe_added: string[] = []
    synced: boolean = true
}

export class ProofFactorHook extends Serializable {
    _id: string
    title: string = ''
    course_ids: string[] = []
    series_ids: number[] = []
    url: string = ''
    created: number

    series_titles?: string[]
}

export class ProofFactorHookEditable extends Serializable {
    title: string = ''
    course_ids: string[] = []
    series_ids: number[] = []
    url: string = ''
}


export class GroupClasses {
    course_id: string = ''
    classes: number[] = []
    course_title?: string
    class_titles?: string[]
}

export enum CourseType {
    LIVE = 'live',
    ONLINE = 'online',
    TRIAL = 'trial'
}

export const CourseTypeText = {
    [CourseType.LIVE]: '實時課程',
    [CourseType.ONLINE]: '預錄課程',
    [CourseType.TRIAL]: '試堂課程'
}

export enum SeriesType {
    LIVE = 'live',
    ONLINE = 'online'
}

export let blogFooterScopeOptions: Array<{ value: string, label: string }> = [
    {
        label: 'Global',
        value: 'global'
    },
    {
        label: 'Instructor',
        value: 'author'
    },
    {
        label: 'Blog',
        value: 'blog'
    }
]
